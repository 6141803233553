<template>
  <b-modal id="modal-user-login"
    title="เข้าสู่ระบบ"
    v-model="modalLoginShow"
    hide-footer hide-header
    class="p-0 bg_tran">
    <div class="modal-content2">
        <div class="modal-header" v-if="!resume_login">
          <div class="modal-close" @click="$bvModal.hide('modal-user-login')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          <div class="modal-header-inner">
            <div class="btn-group w-100" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-success lightSweep">ลงชื่อเข้าใช้</button>
              <button type="button" class="btn btn-secondary" @click="openRegister">สมัครสมาชิก</button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="resume_login">
            <div class=" p-0">
              <div class="sidebar-header p-2">
                <div class="row pt-5 pb-3">
                  <div class="col-12 text-center">
                    <div class="m-user-name-icon m-user-big">{{ (old_username.charAt(0)+old_username.charAt(old_username.length - 1)).toUpperCase() }}</div>
                  </div>
                  <div class="col-12 p-2">
                    <h2 class="username_pf">{{ old_username }}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div @click="re_login()" class="button button-big button-signup button-round mgt15">ลงชื่อเข้าใช้ด้วยชื่อนี้</div>
            <div class="clearfix"></div>
            <div id="btnGoForgotPassword" class="btn button-link text-white pt-2" @click="changeUsername">ลงชื่อเข้าใช้ด้วยบัญชีอื่น ?</div>
        </div>
        <div v-else>
            <form class="login-form" ref="loginform"  @submit.stop.prevent="handleSubmit" >
              <div class="form-group form-special pb-1 pt-2">
                <img src="@/assets/images/5starcirclewhite.png" class="p-5" loading="lazy" alt="" width="70%" v-if="AGENT_S_BASE=='5STARLOTTO'">
                <img src="@/assets/new/Login.webp" loading="lazy" alt="" width="70%" v-else>
                <div class="input-holder">
                  <input v-model="member_username" type="text" inputmode='text' name="username" autofocus class="form-control x-form-control" placeholder="ชื่อผู้ใช้"  autocomplete="off">
                </div>
                <div class="jErr"></div>
              </div>
              <div class="form-group form-special">
                <div class="input-holder">
                  <input  v-model="member_password" type="password" name="password" class="form-control x-form-control" placeholder="รหัสผ่าน">

                </div>
                <div class="jErr"></div>
              </div>
              <div @click="login()" class="button button-big button-signup button-round mgt15">ลงชื่อเข้าใช้</div>
            </form>
            <div class="clearfix"></div>
            <div class="text-center">
              <div class="text-white fs12 my-2">ยังไม่เป็นสมาชิก?
                <a id="btnJoinnow" class="button-link-blue pdl5"
                @click="openRegister" href="javascript:;" >เข้าร่วมตอนนี้</a>
                </div>
              <div id="btnGoForgotPassword" class="btn button-link text-white" @click="openForgetPassword">ลืมรหัสผ่าน?</div>
            </div>
          </div>
        </div>
      </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'Home',
  data () {
    return {
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE,
      casinoEnable: false,
      indexNews: 0,
      modalLoginShow: false,
      headerBgVariant: 'bgModalHeaderLogin',
      headerTextVariant: 'dark',
      bodyBgVariant: 'primary-login',
      bodyTextVariant: 'dark',
      footerBgVariant: 'dark',
      footerTextVariant: 'dark',
      member_username: null,
      member_password: null,
      old_username: 'samsung7',
      resume_login: true,
      AGENT_EXT_NAME: process.env.VUE_APP_EXT_NAME,
      xcode: [
        'c857a979'
      ],
      userObject: {
        password: '',
        url: '',
        username: '',
        xcode: ''
      }
    }
  },
  async created () {
    if (this.isMember) {
      if (this.$route.path === '/authenticate') {
        // TODO : add bypass login
        // const url = this.$route.params.url ? this.$route.params.url : ''
        console.log('------- bypass login ------')
        this.userObject.url = this.$route.query.url ?? ''
        this.userObject.username = this.$route.query.username ?? ''
        this.userObject.password = this.$route.query.password ?? ''
        this.userObject.xcode = this.$route.query.xcode ?? ''
        if (this.userObject.username !== '' && this.userObject.password !== null && this.xcode.includes(this.userObject.xcode)) {
          const isSucces = await this.loginAction({
            username: this.userObject.username,
            password: this.userObject.password
          })
          if (isSucces) {
            this.clearAfKey()
            this.clearAgKey()
            this.setLoading(false)
            this.$router.replace('/hotgame')
            this.modalLoginShow = false
            this.member_username = null
            this.member_password = null
            this.requestUsername()
            this.getMyAccountBanks()
            this.getUserInfo()
            this.getPromotionsList()
            this.getBalanceInfo()
            this.reqConfig(process.env.VUE_APP_AG_ID)
            // window.location.reload()
          } else {
            this.setLoading(false)
            window.location.href = this.userObject.url + '?msg=Username หรือ password ไม่ถูกต้อง'
            return false
          }
        } else {
          window.location.href = this.userObject.url + '?msg=ไม่พบข้อมูลผู้ใช้'
        }
      }
    }
    const user = this.$store.getters?.getUsername?.toString() ?? ''
    const pass = this.$store.getters?.getPassword?.toString() ?? ''
    console.log('created =>', user, pass)
    if (user !== '' && pass !== '') {
      this.old_username = user
      this.resume_login = true
    } else {
      this.resume_login = false
    }
  },
  methods: {
    ...mapActions(['reqConfig', 'clearAgKey', 'clearAfKey', 'setLoading', 'loginAction', 'getBalanceInfo', 'requestUsername', 'getMyAccountBanks', 'getUserInfo', 'getPromotionsList']),
    ...mapGetters(['getUsername', 'getPassword']),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    }),
    openLine () {
      window.open(this.getAgentNews.link)
    },
    async login () {
      if (!this.member_username && !this.member_password) {
        return false
      } else {
        this.setLoading(true)
        const isSucces = await this.loginAction({
          username: this.member_username,
          password: this.member_password
        })
        if (isSucces) {
          this.clearAfKey()
          this.clearAgKey()
          this.setLoading(false)
          this.$router.replace('/hotgame')
          this.modalLoginShow = false
          this.member_username = null
          this.member_password = null
          this.requestUsername()
          this.getMyAccountBanks()
          this.getUserInfo()
          this.getPromotionsList()
          this.getBalanceInfo()
          this.reqConfig(process.env.VUE_APP_AG_ID)
          // window.location.reload()
        } else {
          this.setLoading(false)
          this.$refs.loginform.reset()
          return false
        }
      }
    },
    async re_login () {
      this.member_username = this.$store.getters?.getUsername?.toString() ?? ''
      this.member_password = this.$store.getters?.getPassword?.toString() ?? ''
      await this.login()
    },
    changeUsername () {
      this.resume_login = false
    },
    openRegister () {
      // this.$router.replace('/register-verify')
      this.$bvModal.hide('modal-user-login')
      this.$bvModal.show('modal-user-register')
    },
    openForgetPassword () {
      this.$router.replace('/forgetpassword')
      this.$bvModal.hide('modal-user-login')
      this.$bvModal.hide('modal-user-register')
    },
    handleSubmit (e) {
      console.log('Sign In Button Pressed')
    }
  }
}
</script>
<style lang="less">
.m-user-big{
  width: 120px;
  height: 120px;
  font-size: 73px;
}
#modal-user-login{
height: 100% !important;
}
#modal-user-register{
height: 100% !important;
}
.modal-content {
    // background-color: transparent !important;
    background: var(--bodyBg) !important;
}
.bgModalHeaderLogin {
  background: linear-gradient(180deg, var(--color-1-main));
}

.bg-primary-login {
  // background: linear-gradient( 225deg, #420000 0%, rgb(144 3 34) 40%, #420000 100%);
  background: linear-gradient(132deg, #ce878e 0%, rgb(140 140 140) 40%, rgb(152 21 21) 100%);
  border-width: 3px;
  border-style: solid;
  border-color: #7a3438;
  border-radius: 5px;
  // background-image: linear-gradient(#b5b4b4 1px, transparent 1px), linear-gradient(to right, #c8a042 1px, #c8a042 1px);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
u,
div {
  font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  // background: -webkit-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: -o-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: -moz-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  top: 0;
  left: -100%;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

.login100-form-btn {
  background: #900322;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}
</style>
